(function($) {

    $(document).ready(function() {

        $('.js-slick').slick({
            autoplay: true,
            autoplaySpeed: 3500,
        });


        /**
         *
         * Login Ligttbox
         *
         */
        $('li.js-login a, a.js-login').on('click', function(evt) {
            evt.preventDefault();

            var element = $(this).attr('href');

            $(element).removeClass('isHidden');
        });

        $('.lightbox-wrap').on('click', function(evt) {
            if ($(evt.target).has('.lightbox').length) {
                $('.lightbox-wrap').addClass('isHidden');
            }
        });


        // if ('addEventListener' in window) {
        //     window.addEventListener('resize', function(){
        //         setTimeout(function() {
        //             sameHeights();
        //         }, 100);
        //     });
        //     window.addEventListener('load', function(){
        //         sameHeights();
        //     });
        // }

        // function sameHeights(selector) {
        //     var selector = selector || '[data-key="sameHeights"]',
        //         query = document.querySelectorAll(selector),
        //         elements = query.length,
        //         max = 0;
        //     if (elements) {

        //         while (elements--) {
        //             var element = query[elements];
        //             if (element.clientHeight > max) {
        //                 max = element.clientHeight;
        //             }
        //         }
        //         elements = query.length;
        //         while (elements--) {
        //             var element = query[elements];
        //             element.style.height = max + 'px';
        //         }
        //     }
        // }




        /**
         * Menu Toggle
         */
        var menuOpen = false;
        $('.js-menu-toggle').on('click', function(evt) {
            evt.preventDefault();

            $(this).toggleClass('isOpen');
            $('.js-nav-primary').toggleClass('isOpen');
            $('.js-site-head').toggleClass('isOpen');
        });

        $('.js-menu-close').on('click', function(evt) {
            evt.preventDefault();
            closeMenu();
        });


        function closeMenu() {
            $('.js-menu-toggle').removeClass('isOpen');
            $('.js-nav-primary').removeClass('isOpen');
            $('.js-site-head').removeClass('isOpen');
        }

        $(window).resize(function(){
            closeMenu();
        });

        $('.js-nav-primary a').on('click', function(){
          closeMenu();
        });


        /*
         * Alt Header on scroll
         */
        // stickHeader();
        // window.addEventListener('scroll', function(e){
        //     stickHeader();
        // });

        // function stickHeader() {
        //     var distanceY = window.pageYOffset || document.documentElement.scrollTop,
        //         shrinkOn = 100,
        //         header = $('.js-site-head');
        //     if (distanceY > shrinkOn) {
        //         header.addClass('isSticky');

        //     } else {
        //         if (header.hasClass('isSticky')) {
        //             header.removeClass('isSticky');
        //         }
        //     }
        // }


        /**
         * Tabs
         */
        // $('.js-accordion-control').on('click', function(evt) {
        //     evt.preventDefault();

        //     var $tab = $(this).closest('.js-accordion-item');
        //     var $this = $(this);

        //     $tab.find('.accordion-body').toggleClass('isActive');
        //     $this.toggleClass('isActive');
        // });


        /**
         *
         * SmoothScroll
         *
         */
        // $('a[href*="#"]:not([href="#"])').click(function() {
        //     if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        //       var target = $(this.hash);
        //       target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        //       if (target.length) {
        //         $('html, body').animate({
        //           scrollTop: target.offset().top - 80
        //         }, 1000);
        //         return false;
        //       }
        //     }
        //   });





        /**
         *
         * Slick
         *
         */
        //$('.js-slick').slick();


        /**
         *
         * AOS int
         *
         */
        // AOS.init({
        //     offset: 200,
        //     easing: 'ease-in-out-cubic'
        // });

    });

})(jQuery); // Fully reference jQuery after this point.